@use "sass:math";
$mainColor:#682628;

:root {
    --primary-color:$mainColor;
    --swiper-theme-color: #fff;
}

@import 'reset';

@import './fonts.css';

svg {
    path {
        fill: green;
    }
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', sans-serif;

    font-size: 16px;

    @media screen and (max-width: 800px) {
        font-size: 12px;
    }
}


h1{
    font-size: 2rem;
}
h2{
    font-size: 2rem;
}
strong,
.accent,
a {
    color: $mainColor;
    text-decoration: none;
    font-weight: bold;
}

a {
    &:hover {
        text-decoration: underline;
    }
}

ul {
    padding: 0;

    li {
        display: block;
    }
}

.no-break {
    display: inline-block;
}

.logo,
.logo img {
    // height: 80px;
    height: 100%;
    max-height: 69px;
    width: auto;
    display: block;
    margin: auto;

    img {
        height: 100%;
    }
}


footer {
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    display: block;

    .logo img {
        padding: 10px 0;
        margin: 0px auto 5px;
    }
}

header {
    height: 60px;
    margin: 20px;

    h1 {
        text-align: left;
        height: 100%;
        padding: 0;
        margin: 0;
    }
}

@import 'locations';
@import 'services';
// @import './foundation/css/foundation.css';
// @import './foundation/css/app.css';


@import 'https://unpkg.com/swiper@7/swiper-bundle.min.css';
@import 'swiper';